
import Vue from "vue";
import Message from "./components/Message.vue";

// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
  hash: string
  title: string
  preamble: string
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
name: "app",
computed: {
  hash() {
    return this.$store.getters.hash;
  },
  title() {
    return this.$store.getters.title;
  },
  preamble() {
    return this.$store.getters.preamble;
  },
},
components: {
  Message,
},
});
