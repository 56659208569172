export interface IState {
	title: string;
	preamble: string;
	linkText: string;
	linkURI: string;
	expandText: string;
	hash: string;
  }
  

  const defaultState: IState = {
	title: "",
	preamble: "",
	linkText: "",
	linkURI: "",
	expandText: "",
	hash: "",
  };
  
  export default defaultState;
  