
import Vue from "vue";

interface MessageData {
  isOpen: boolean;
  isMobile: boolean;
}

interface MessageMethods {
  toggleMessage(): void;
}

interface MessageProps {
  hash: string
}

interface MessageComputed {
  title: string,
  preamble: string,
  linkText: string,
  linkURI: string,
  expandText: string,
  expandButtonText: string
}

export default Vue.extend<
  MessageData,
  MessageMethods,
  MessageComputed,
  MessageProps
>({
  name: "app",
  data() {
    return {
      isOpen:
        !localStorage.getItem(`lul-important-message-${this.hash}`) ||
        localStorage.getItem(`lul-important-message-${this.hash}`) ===
          "is-open-true"
          ? true
          : false,
      isMobile: 
        window.innerWidth < 768
    };
  },
  methods: {
    toggleMessage() {
      this.isOpen = !this.isOpen;
      localStorage.setItem(
        `lul-important-message-${this.hash}`,
        `is-open-${this.isOpen}`
      );
    },
  },
  computed: {
    title() {
      return this.$store.getters.title;
    },
    preamble() {
      return this.$store.getters.preamble;
    },
    linkText() {
      return this.$store.getters.linkText;
    },
    linkURI() {
      return this.$store.getters.linkURI;
    },
    expandText() {
      return this.$store.getters.expandText;
    },
    expandButtonText() {
      let expandText = ''
      if(this.isMobile && this.isOpen){
        expandText = 'Fäll ihop';
      } else if(this.isMobile && !this.isOpen){
        expandText = 'Fäll ut';
      }else if(!this.isMobile && this.isOpen){
        expandText = 'Fäll ihop meddelande';
      }else if(!this.isMobile && !this.isOpen){
        expandText = 'Fäll ut meddelande';
      }
      return expandText;
    }
  },
  mounted() {
    let thisObject = this;
    window.addEventListener('resize', function(event) {
      if(window.innerWidth < 768) {
        thisObject.isMobile = true;
      }
      else {
        thisObject.isMobile = false;
      }
    });
  },
  components: {},
  props: {
    hash: () => ""
  },
});
