import { Getter } from "vuex";
import { IState } from "./state";

const title: Getter<IState, IState> = (state) => state.title;
const preamble: Getter<IState, IState> = (state) => state.preamble;
const linkText: Getter<IState, IState> = (state) => state.linkText;
const linkURI: Getter<IState, IState> = (state) => state.linkURI;
const expandText: Getter<IState, IState> = (state) => state.expandText;
const hash: Getter<IState, IState> = (state) => state.hash;



export default {
  title,
  preamble,
  linkText,
  linkURI,
  expandText,
  hash,
};
